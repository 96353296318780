import React, { ReactElement } from "react"
import { Col, Container, Row } from "react-bootstrap"
import classNames from "classnames"

interface IProps {
  background?: boolean
  heading?: string
  children?: ReactElement | string
}

/**
 * Heading for pages.
 */
const PageHeading = ({ background, heading, children, ...rest }: IProps) => (
  <section
    className={classNames({
      "primary-background": background,
    })}
    {...rest}
  >
    <Container className={classNames("text-center", heading ? "py-5" : "py-3")}>
      <Row>
        {heading && (
          <Col xs={12}>
            <h1 className="mb-3">{heading}</h1>
          </Col>
        )}
        {children && (
          <>
            <Col lg={1} className="d-none d-lg-block" />
            <Col lg={10} xs={12}>
              {children}
            </Col>
            <Col lg={1} className="d-none d-lg-block" />
          </>
        )}
      </Row>
    </Container>
  </section>
)

export default PageHeading
